.standby-state-page{
    .icon-standby-state{
        width: 40px;
        height: 40px;
        border: 2px solid #8425CB;
        border-radius: 50%;
        p{
            color: #8425CB !important;

        }
    }
}
