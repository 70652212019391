:root{
    --primary-color : #FEF5FE;
}
.mySwiper{
    height: 500px;
    overflow: hidden;
    .swiper-slide{
        width: 100% !important;
        img{
            object-fit: cover;
            object-position: center;
        }
    }
}
.bg-primary-backgroup-color{
    background-color: var(--primary-color) ;
}