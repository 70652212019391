.login-page{
    width: 100%;
    input:focus{
        color: black !important;
    }
    .component-login{
        .custorm-page-login{
            height: 500px;
            img{
                object-fit: cover;
                object-position: center;
            }
        }
        .titel{
            h3{
                cursor: pointer;
                transition: 0,5s ease-in-out;

            }
            h3.avtice{
                color: #9C3CE7;
                position: relative;
                transition: 0,5s ease-in-out;
            }
            h3.avtice::after{
                content: "";
                width: 100%;
                height: 3px;
                position: absolute;
                bottom: -10px;
                left: 0;
                background-color: #9C3CE7;
                transition: 0,5s ease-in-out;

            }
        }
        .custorm-btn{
            padding: 12px;
            border: none;
            outline: none;
            border-radius: 26px;
            background-color: #9C3CE7;
            color: white;
        }
    }
  
}