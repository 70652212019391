header{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: #011022;
    color: white;
  
    i, p{
        padding: 0;
        margin: 0;
    }
    .my-pay-money{
        transition: 0.5s ease-in-out;
        position: relative;
        .my-pay-money-item{
            position: absolute;
            top: 30px;
            right: 0;
            background-color: #414561;
            width: 180px;
            border-radius: 5px;
            .icon-card{
                width: 30px;
                height: 30px;
                border-radius: 5px;
                background-color: #FA2843;
                color: white;
            }
        }  
    }
}
.h-80-header{
    height: 80px;
}
.h-80-mobi{
    height: auto;
    min-height: 100px;
}