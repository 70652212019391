.depositWithdraw-page{
    color: white;
    .box-header{
        background-color: #2E3C4C;
        border-radius: 10px;
    }
    .form-control:focus{
        color: white !important;
    }
    .titel{
        h3{
            cursor: pointer;
            transition: 0,5s ease-in-out;

        }
        h3.avtice{
            color: #dd2c00;
            position: relative;
            transition: 0,5s ease-in-out;
        }
        h3.avtice::after{
            content: "";
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: -10px;
            left: 0;
            background-color: #dd2c00;
            transition: 0,5s ease-in-out;

        }
    }
}
.custorm-button{
    background-color: #dd2c00;
    color: white;
    border: none;
    outline: none;
}
.modal-custorm{
    .modal-content{
        background-color: #011022;
        color: white;
        input{
            background: transparent;
            color: white;
        }
        
    }
}
.form-control:focus{
    color: white !important;
}
.form-input-amount{
    height: 40px;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 8px;
    input{
        border: none;
        outline: none;
        padding-left: 10px;
    }
    .set-all-amount{
        padding: 2px 10px;
        background-color: #dd2c00;
        color: white;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 5px;
        cursor: pointer;
        right: 5px;
    }
}