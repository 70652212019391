*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 15px;
    -webkit-appearance: none;
}
html {
    scroll-behavior: smooth; 
  }
:root{
    --color-black : #000000 ;
    --color-custom : #011022 ;
}
.bg-primary-custom{
    background-color: var(--color-custom) !important;
}
i,p{
    padding: 0;
    margin: 0   !important;
}
.warper{
    overflow: hidden;
}
input{
    box-shadow : none !important;
    &:focus{
        border-color:  #ccc !important;
    }
}
.color-green{
    color: #28C76F !important;
}
.color-gary{
    color: #707A8A;
}
.bg-pink{
    background-color: #FAF5FF;
}
.modal-custorm{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5) ;
    .modal-custorm-item{
        position: relative;
        width: 100%;
        height: 100%;
    }
}
.bg-black{
    
}
.bg-green{
    background-color: #28C76F;
}
.bg-red{
    background-color: #EA5455;
}
.App{
    width: 100vw;
    height: 100vh;
}
.input-password{
    width: 100%;
    height: 46px;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    input{
        border: none;
        outline: none;
        box-shadow : none !important;
    }
    .icon-eye{
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.bg-transparent{
    background: transparent;
}
main{
    padding-top: 80px;   
}
table.table{
    td,th{
        background: transparent;
        color: white;
    }
   
}
.update-password{
    
    .btn-update-password{
        background-color: #EA5455 !important;
        color: white;
        border: none;
        outline: none;
    }
    .icon-close-update-password{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
    }
}
.color-violet{
    color: #8425CB;
}
.btn-loadmore{
    background-color: #EA5455;
    color: white;
    padding: 10px 20px;
    width: 200px;
    border-radius: 10px;
    cursor: pointer;
}
.text-animation{
    animation: custormAnimationText 2s ease-in-out 0.5s  infinite alternate forwards ;
}
.text-animation-h1{
    animation: custormAnimationTextH1 2s ease-in-out 0.5s  infinite alternate forwards ;
}
.text-gray{
    color: #ff5a5a !important;
}
.modal-custorm{
    .modal-content{
        background-color: #011022;
        color: white;
        input{
            background: transparent;
            color: white;
        }
        
    }
}
@keyframes custormAnimationText  {
    0% {
     color: white;
     transform: scale(1);
    }
    25%{
        color: #EA5455;
        transform: scale(1.1);
        font-weight: 540;
    }
    50%{
        color: #EA5455;
        transform: scale(1.2);
        font-weight: bold;
    }
    75%{
        color: #EA5455;
        transform: scale(1.1);
        font-weight: 540;
    }
    100% {
             color: white;
        transform: scale(1);
    }
  }
  @keyframes custormAnimationTextH1  {
    0% {
     color: white;
     transform: scale(1);
    }

    50%{
        transform: scale(1.2);
    }
 
    100% {
             color: white;
        transform: scale(1);
    }
  }
.color-violet{
    color: #8425CB !important;

}
.table-responsive {
    width: 100%;
    padding: 0 10px;
  }
  
  .table-responsive th,
  .table-responsive td {
      padding: 8px;
      border-top: 1px solid #ddd; 
      border-left: none; 
      border-right: none;
      text-align: left;
  }
  .table-responsive tr td:first-child,
  .table-responsive tr th:first-child {
      border-left: 1px solid #ddd;
  }
    .table-responsive tr td:last-child,
  .table-responsive tr th:last-child {
      border-right: 1px solid #ddd; 
  }
    .table-responsive tr:last-child td {
      border-bottom: 1px solid #ddd;
  }
  
  @media (max-width: 768px) {
    .table-responsive thead {
      display: none;
    }
    .table-responsive td:first-child {
        background-color: white;
        color: black;
      }
    .table-responsive tr {
      display: block;
      margin: 15px 10px;
    }
  
    .table-responsive td {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      border: 1px solid #ddd;
      position: relative;
    }
    .table-responsive tr:first-child td {
        border-top: 1px solid #ddd;
    }
    .table-responsive td::before {
      content: attr(data-label);
      font-weight: bold;
      margin-right: 10px;
    }
  }
  .pointer-custom {
    cursor: pointer;
}
.modal-custom{
    .modal-content{
        background-color: #011022;
        color: white;
        input{
            color: white;
            background: transparent;
        }
    }

}
.color-bule{
    color: #0D6EFD !important;
}
.lang-text{
    cursor: pointer;
}
.lang-text.active{
    color: red;
}
.text-uppercase {
    text-transform: uppercase;
  }
.min-h-100{
    min-height:  calc(100vh);
}
.max-h-100{
    max-height:  calc(100vh);
}
.h-auto-custorm{
    height: calc(100vh - 315px);
}
.h-login{
    height: calc(100vh - 76px);
}
.navbar-custorm{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 80px !important;
    z-index: 10;
    background-color: #011022 !important;
   
    button{
        color: white !important;
    }
    button.active{
        color: red !important;
    }
}
@media (max-width: 768px) {
    .chaport-container .chaport-launcher .chaport-launcher-button{
        bottom: 90px  !important;
        left: 20px !important;
    }
}
