.ps-sidebar-container {
    background: transparent !important;

    .ps-menuitem-root {
        width: 100%;
        .ps-menu-button {
            width: 100%;
            &:hover{
                background: transparent;
            }
            .ps-menu-label {
                display: flex;
                justify-content: start;
                align-items: center;

                i,
                p {
                    color: white !important;
                    padding: 0 !important;
                    margin: 0;
                }
            }
        }
    }


}
.slide-bar.mobi{
    position: fixed;
    top: 80px;
    left: 0;
    bottom: 0;
    z-index: 5;
}