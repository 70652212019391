.modal-user-item{
    color: white;
    width: 400px;
    max-width: 100% !important;
    height: 100vh;
    background-color: #02142B;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    .modal-image{
        height: 80px;
        width: 80px;
        border-radius: 50%;
        overflow: hidden;
    }
    .icon-close{
        position: absolute;
        top: 10px;
        right: 30px;
        font-size: 20px;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &:hover{
            color: red;
            transform: scale(1.2);
        }
    }
    .user-infro{
        overflow-y: scroll;
        overflow-x: hidden;
        .titel{
            h3{
                
            }
        }
        .btn-accuracy {
            border: 1px solid #f59942;
            border-radius: 10px;
            color: #f59942;
            cursor: pointer;
            padding: 9px 28px;
        }
        .image-user{
            img{
                width: 80px;
                height: 80px;
                object-fit: cover;
                object-position: center;
                cursor: pointer;
            };
            button{
                outline: none;
                background: transparent;
                border:1px solid #7367F0;
                color: #7367F0;
                border-radius: 10px;
            }
            form.show{
                input{
                    border: 1px solid white;
                    color: white;
                }
            }
        
        }
    }
  
}
.he-auto{
    height: calc(100vh - 60px); 
}
.he-500{
    min-height: 550px;
    height: auto;// đanng dùng
    // height: 550px; nếu trên k được bật cái bên dưới đóng cái bên trên
}
.modal-edit-user ul.tab-user .nav-item{
    margin-left: 15px;
}