.page-home{
    color: white;
    height: auto;
    .iframe-home{
        height: 47px;
    }
    .marqueeWidgetpreview{
        color: white;
        svg{
            background-color: white !important;
        }
        .coin-marquee-item-info__row{
            color:  white !important;
        }
        .coin-marquee-header-signature{
            display: none;
            
        }
        .coin-marquee-container{
            width: 100%;
        }
    }
    .table-convert{
        input{
            border: 1px solid #ccc;
            color: white;
            border-radius: 8px;
        }
    }
    .btn-acction{
        color: white !important;
        border: none;
        outline: none;
        border-radius: 5px;
    }
    .custorm-box{
        width: 100%;
        height: auto;
        border: 1px solid #ccc;
        border-radius: 10px;
        input{
            border: none;
            outline: none;
        }
    }
    .btn-invest{
        border: none;
        outline: none;
        width: 100px;
        height: 35px;
        border-radius: 5px;
        color: white;
        background-color: #EA5455;
    }
}
.custorm-media{
    width: calc(100% - 250px);
}
