.modal-edit-user{
    color: white;
    width: 400px;
    height: 100vh;
    background-color: #02142B;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    input{
        color: white;
    }
    .edit-user-custorm, .tab-item-user {
        overflow: hidden;
        overflow-y: scroll;
        height: 500px;
        padding-bottom: 20px;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    
    .edit-user-custorm::-webkit-scrollbar, 
    .tab-item-user::-webkit-scrollbar {
        width: 8px; 
    }
        .edit-user-custorm::-webkit-scrollbar-track, 
    .tab-item-user::-webkit-scrollbar-track {
        background: #f1f1f1; 
        border-radius: 10px;
    }
    .edit-user-custorm::-webkit-scrollbar-thumb, 
    .tab-item-user::-webkit-scrollbar-thumb {
        background-color: #888; 
        border-radius: 10px;   
        border: 2px solid #f1f1f1;  
    }
    
    /* Hiệu ứng khi hover trên thanh trượt */
    .edit-user-custorm::-webkit-scrollbar-thumb:hover, 
    .tab-item-user::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .icon-close{
        position: absolute;
        top: 10px;
        right: 30px;
        font-size: 20px;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        &:hover{
            color: red;
            transform: scale(1.2);
        }
    }
    ul.tab-user{
        border: none;
        .nav-item{
            align-items: center;
            background-color: #011022;
            background-color: var(--background--primary);
            border: 2px solid #1c3d61 !important;
            border-radius: 50%;
            display: flex;
            height: 49px;
            justify-content: center;
            overflow: hidden;
            width: 49px;   
            button{
                &:hover{
                    border: none;
                } 
            }    
           
        }
        .nav-item:has(button.active) {
            background-color: #1C3D61;
            border: none;
        }
        .nav-item button.active{
            background: transparent;
            border: none;
            outline: none;
            color: white;
         
          }
    }
    .box-image{
        .btn-upload-img, .upload__image-wrapper{
            background-color: white;
           width: 150px;
            height: 150px;
            border-radius: 10px;
        }
    }
   .btn-step{
    border: 1px solid;
    color: white;
    border-radius: 10px;
    cursor: pointer;
   }
}